import React, { useState, useEffect } from 'react';
import { Book, Images } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { SpeedInsights } from '@vercel/speed-insights/react';

const books = [
  { title: "The modern architecture since 1900", author: "William J. R. Curtis", cover: "/images/covers/modern-architecture.png", buyLink: "https://amzn.to/4g8wIue", freeLink: "https://archive.org/embed/modernarchitectu0000curt", summary: "A comprehensive history of modern architecture.", price: "$53", buttons: ["buy", "free"] },

  { title: "Towards a New Architecture", author: "Le Corbusier", cover: "/images/covers/tonew-architecture.png", buyLink: "https://amzn.to/4dYlsyS", freeLink: "https://archive.org/embed/towardsnewarchit00leco_0", summary: "A manifesto for modern architecture.", price: "$30", buttons: ["buy", "free"] },

  { title: "The New Architecture and the Bauhaus", author: "Walter Gropius, 1935", cover: "/images/covers/new-architecture.png", buyLink: "https://amzn.to/3MssiB5", freeLink: "https://archive.org/embed/newarchitectureb00grop", summary: "An exploration of the Bauhaus movement.", price: "$30", buttons: ["buy", "free"] },

  { title: "The Work of Charles and Ray Eames: A Legacy of Invention", author: "Donald Albrecht", cover: "/images/covers/eams.png", buyLink: "https://amzn.to/3TBCD1B", freeLink: "https://archive.org/embed/workofcharlesray0000dona", summary: "Explores the innovative legacy of Charles and Ray Eames in design, architecture and multimedia, celebrating their influence on modern design.", price: "$58", buttons: ["buy", "free"] },

  { title: "Design of the 20th Century", author: "Charlotte and Peter Fiell", cover: "/images/covers/d-20-c.png", buyLink: "https://amzn.to/4cN943D", freeLink: "https://archive.org/embed/designof20thcent0000fiel", summary: "Evolution and icons of modern design.", price: "$20", buttons: ["buy", "free"] },

  { title: "Frank Lloyd Wright: A Biography", author: "Meryle Secrest", cover: "/images/covers/frank-lw.png", buyLink: "https://amzn.to/478QNMU", freeLink: "https://archive.org/embed/franklloydwright00farr", summary: "Life and works of architect Frank Lloyd Wright.", price: "$30", buttons: ["buy", "free"] },

  { title: "William Morris: A Life for Our Time", author: "Fiona MacCarthy", cover: "/images/covers/a-life-for.png", buyLink: "https://amzn.to/3ARdSb1", freeLink: "https://archive.org/embed/williammorrislif0000fion", summary: "William Morris: art, politics and social reform.", price: "$35", buttons: ["buy", "free"] },
];

const DesignCultureLayout = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAboutOpen, setIsAboutOpen] = useState(false); // Stato per il popup "About"
  const [formData, setFormData] = useState({
    name: '',
    author: '',
    link: ''
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    // Simula un caricamento di 1 secondo
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleSuggestBook = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenAbout = () => {
    setIsAboutOpen(true); // Apri popup "About"
  };

  const handleCloseAbout = () => {
    setIsAboutOpen(false); // Chiudi popup "About"
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://formspree.io/f/xyzgvgyb', {  // Sostituisci con il tuo endpoint Formspree
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccessMessage('The book was successfully suggested!');
        setErrorMessage('');
        setFormData({ name: '', author: '', link: '' });  // Reset del form
      } else {
        setErrorMessage('Error while sending suggestion. Please try again later. Sorry.');
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('Error while sending suggestion. Please try again later. Sorry.');
    }
  };

  const handleBoxClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  if (isLoading) {
    return (
      <div className="fixed inset-0 bg-white flex items-center justify-center">
        <div className="animate-pulse text-4xl font-instrument font-serif text-gray-700">
          Design your Culture
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white max-w-7xl mx-auto px-4 py-8 animate-fade-in">
      <header className="text-center mb-8">
        <h1 className="text-4xl font-instrument font-serif mb-2 text-gray-700">Design <a className='italic'>your </a> Culture</h1>
        <p className="text-gray-600 mb-4">
          A selection of books from history's most famous designers to build up your design culture
        </p>

        <div className="flex justify-center gap-4">
          <button
            onClick={handleSuggestBook}
            className="px-4 py-2 border border-gray-300 rounded-full hover:bg-gray-100"
          >
            Suggest a book
          </button>
          <button
            onClick={handleOpenAbout} // Apre il popup "About"
            className="px-4 py-2 bg-gray-800 text-white rounded-full hover:bg-gray-700"
          >
            About
          </button>
        </div>
      </header>
      
      <main className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {books.map((book, index) => (
          <div 
            key={index} 
            className="relative bg-gray-100 p-4 rounded-lg w-full h-80 flex flex-col justify-between group overflow-hidden"
            onClick={() => handleBoxClick(index)}
          >
            <img src={book.cover} alt={book.title} className="w-full h-48 object-contain mb-4" />
            <div>
              <h3 className="text-xl font-instrument font-serif text-gray-700">{book.title}</h3>
              <p className="text-sm text-gray-400">{book.author}</p>
              <div className="absolute top-4 right-4 bg-gray-200 text-gray-500 text-sm px-2 py-1 rounded-full">
                {book.price}
              </div>

            </div>
            <div className="absolute inset-0 bg-black bg-opacity-20 backdrop-blur-sm flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 p-4 text-center">
              <p className="text-white mb-4">{book.summary}</p>
              <div className="flex gap-2">
                {book.buttons.includes("buy") && (
                  <a href={book.buyLink} className="px-4 py-2 bg-green-700 text-white rounded-full m-2">Buy Now</a>
                )}
                {book.buttons.includes("free") && (
                  <a href={book.freeLink} className="px-4 py-2 bg-white text-black rounded-full m-2">Free version</a>
                )}
              </div>
            </div>

          </div>
        ))}
      </main>
      <div className="text-center text-sm text-gray-400 mt-4">
                &copy; 2024 Made by <a href="https://tmms.me" className='text-gray-400 hover:text-gray-800 duration-200'>Tommaso</a> <a className='text-yellow-500'>(No tracker on this site)</a>, follow us on <a href="https://www.instagram.com/creative_minds_archive" target="_blank" rel="noopener noreferrer" className="hover:text-gray-800 duration-200">instagram</a>
        </div>

      {/* Modale per suggerire un nuovo libro */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 m-4 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-2xl font-instrument font-serif mb-1">Suggest a New Book</h2>
            <p className='text-gray-500 mb-4'>To find out if it has been accepted follow our <a href="https://www.instagram.com/creative_minds_archive" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:underline">instagram</a></p>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1 ">Book Name</label>
                <input type="text" name="name" value={formData.name} onChange={handleChange} className="w-full border border-gray-300 p-2 rounded-md shadow-sm" required />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Author</label>
                <input type="text" name="author" value={formData.author} onChange={handleChange} className="w-full border border-gray-300 p-2 rounded-md shadow-sm" required />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Link <a className='text-gray-300'>(optional)</a></label>
                <input type="url" name="link" value={formData.link} onChange={handleChange} className="w-full border border-gray-300 p-2 rounded-md shadow-sm" optional />
              </div>
              <div className="flex justify-end">
                <button type="button" onClick={handleCloseModal} className="px-4 py-2 bg-red-200 text-red-800 rounded-full mr-2">Cancel</button>
                <button type="submit" className="px-4 py-2 bg-gray-800 text-white rounded-full hover:bg-gray-700">Send</button>
              </div>
              {successMessage && <p className="text-green-500 mt-4">{successMessage}</p>}
              {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>}
            </form>
          </div>
          
        </div>
      )}

      {/* Modale per la sezione "About" */}
      {isAboutOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 m-4 rounded-lg shadow-lg max-w-lg w-full relative">
            <button
              className="absolute top-4 right-4 w-6 h-6 bg-gray-200 rounded-full flex items-center justify-center hover:bg-gray-300 z-10" // Aggiungi z-index alto
              onClick={handleCloseAbout} // Chiudi il popup "About"
            >
              <FontAwesomeIcon icon={faXmark} style={{ color: 'gray' }} />
            </button>
            <div className="relative mb-4">
              <img src="/images/about-cover-3.png" alt="About Image" className="w-full h-50 object-cover rounded-lg mb-4" />
            </div>
            <h2 className="text-2xl font-instrument font-serif text-gray-800 mb-2">About This Website</h2>
            <p className="text-gray-600 mb-4">
              This website is a curated collection of books from the most influential designers and architects in history. It is designed to help you build a strong foundation in design culture and history.            </p>
              
              <p className='text-xl font-medium font-instrument font-serif text-gray-800'>Buy Now</p>
              <p className='text-gray-600'>Support the site by shopping through our Amazon affiliate link.</p>
              <p className='text-xl font-medium text-gray-800 font-instrument font-serif mt-2'>Free Version</p>
              <p className='text-gray-600'>You can borrow it (where available) in the virtual library of The Internet Archive</p>         
              </div>
        </div>
        
      )}
      <SpeedInsights />
    </div>
  );
};

export default DesignCultureLayout;