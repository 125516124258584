import React from 'react';
import { Helmet } from 'react-helmet-async';
import DesignCultureLayout from './components/DesignCultureLayout';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Creative Minds Archive</title>
        <meta name="description" content="Creative Minds Archive is a curated collection of the most influential books by designers and architects in history. Discover and purchase the best design books." />
        <meta name="keywords" content="design, architecture, books, design history, designers, architects, book collection" />
        <meta name="author" content="Tommaso" />
      </Helmet>
      <DesignCultureLayout />
    </div>
  );
}

export default App;